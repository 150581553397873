/* The component style is inserted here. */
@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
    transform: scale(1);
  }
}
.modal {
  @apply w-full h-full overflow-y-auto fixed  left-0 top-0 flex;
  @apply inset-0 z-[99999] overflow-y-auto;
  @apply p-2;

  &.closed {
    @apply hidden;
  }
}

.overlay {
  @apply bg-black bg-opacity-40;
}

.closeButton {
  @apply absolute right-2 top-2 text-gray-500;
}

.modalContainer {
  @apply relative rounded-lg shadow-lg  bg-white;
  @apply overflow-auto p-2 transition duration-300;

  animation: scale-up-center 0.3s ease-in-out;
}

.modal-xs .modalContainer {
  @apply w-full max-w-xs md:p-4;
}

.modal-md .modalContainer {
  @apply w-full max-w-lg md:p-4;
}

.modal-lg .modalContainer {
  @apply w-full max-w-xl md:p-4;
}

.modal-xl .modalContainer {
  @apply w-full max-w-2xl md:p-4;
}

.modal-forgot .modalContainer {
  @apply w-full max-w-[498px] h-[352px] md:p-4;
}

.modal-full {
  @apply p-0;

  .modalContainer {
    @apply w-full;
  }
}

.modal-center .modalContainer {
  @apply mx-auto my-auto;
}

.modal-top .modalContainer {
  @apply mx-auto mb-auto mt-5;
}

.modal-right .modalContainer {
  @apply ml-auto mr-5 my-auto;
}

.modal-left .modalContainer {
  @apply mr-auto ml-5 my-auto;
}

.modal-bottom .modalContainer {
  @apply mx-auto mt-auto mb-5;
}
