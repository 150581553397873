.btn {
  @apply text-gray-900  bg-gray-300 border-gray-300 border;
  @apply transition duration-300 ease-in-out;
  @apply inline-flex focus:outline-none  items-center justify-center cursor-pointer;
  @apply rounded-full whitespace-nowrap;
  text &:hover {
    @apply origin-bottom shadow border-primary-600;
  }
  &:active {
    @apply shadow-lg;
    transform: translateY(2px);
  }

  &.outline {
    @apply border-gray-300 bg-white;
  }
}

.primary {
  @apply bg-primary text-white border-primary;

  &:hover {
    @apply text-white border  bg-[#1D7CD7];
  }

  &.outline {
    @apply text-primary border-0 border-primary bg-white;
    // &:hover {
    //   @apply shadow-xs;
    // }
  }
}
.lg {
  @apply h-[44px] px-4 text-[20px];
}

// .md {
//   @apply text-base h-9 px-2;
// }

.xl {
  @apply w-full p-2 font-medium text-xl;
}

.full {
  @apply w-full  p-2 font-bold text-[24px];
}

.disabled,
.loading {
  @apply pointer-events-none bg-primary bg-opacity-60 border-none text-white;
}

.loadingIcon {
  @apply animate-spin mr-2;
}

.default {
  @apply bg-white;
}

.ghost {
  @apply bg-primary-200 text-primary border-primary-200;
}

.round-sm {
  @apply rounded-sm;
}
.round-md {
  @apply rounded-[4px];
}

.round-xl {
  @apply rounded-xl;
}
.round-lg {
  @apply rounded-lg;
}

.round-full {
  @apply rounded-full;
}

.lg {
  @apply w-[200px] h-[44px] text-[20px] font-bold text-center uppercase;
}

.md {
  @apply w-[144px] h-[44px] text-[20px] font-bold text-center uppercase;
}

.sm {
  @apply w-[107px] h-[32px] text-[16px] font-bold text-center uppercase;
}

.xs {
  @apply w-[80px] h-[28px] text-[14px] font-bold text-center uppercase;
}

